import React from 'react';

function InsightCard({ title, image, date, category, description, link }) {
  return (
    <div className="insight-card">
      <img src={image} alt={title} />
      <div className="insight-card-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <span>{category}</span>
        <span>{date}</span>
        <a href={link}>Read more</a>
      </div>
    </div>
  );
}

export default InsightCard;