import React from 'react';
import InsightCard from './InsightCard';
import './Insights.css';


function Insights() {
  const insightItems = [
    {
      title: 'Typography Tips for Design Success',
      image: '',
      date: 'August 15, 2023',
      category: 'UX',
      description: 'Dive into the world of web typography and discover how to choose, pair, and optimize fonts for compelling and readable digital designs.',
      link: '#',
    },
    {
      title: 'Color Psychology in UX',
      image: '',
      date: 'August 15, 2023',
      category: 'UX',
      description: 'Explore how color choices impact user emotions and decision-making in UX design.',
      link: '#',
    },
    {
      title: 'Boosting UX with Microinteractions',
      image: '',
      date: 'August 15, 2023',
      category: 'UX',
      description: 'Discover the power of microinteractions in enhancing user experience and engagement.',
      link: '#',
    },
  ];

  return (
    <section className="insights">
      <div className="container">
        <h2>Design Insights and Inspiration</h2>
        <p>Discover industry insights, expert tips, and design inspiration. Stay updated with the latest trends in web design and user experience.</p>
        <div className="insights-grid">
          {insightItems.map((item) => (
            <InsightCard key={item.title} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Insights;