import React from 'react';
import './CoreSkills.css';

function CoreSkills() {
  return (
    <section className="core-skills">
      <div className="container">
        <h2>Core Design Skills</h2>
        <p>I excel in essential design skills, creating visually stunning and functional digital experiences. From UI design to UX research, my passion is to craft effective and memorable digital solutions.</p>
        <div className="skills-grid">
          <div className="skill">
            <h3>Interaction Design</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '60%' }}></div>
            </div>
          </div>
          <div className="skill">
            <h3>Usability Testing</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '70%' }}></div>
            </div>
          </div>
          <div className="skill">
            <h3>User Research</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '45%' }}></div>
            </div>
          </div>
          <div className="skill">
            <h3>Figma</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '95%' }}></div>
            </div>
          </div>
          <div className="skill">
            <h3>Prototyping</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '80%' }}></div>
            </div>
          </div>
          <div className="skill">
            <h3>Information Architecture</h3>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: '50%' }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoreSkills;