import React from 'react';
import './Portfolio.css';

function Portfolio() {
  const portfolioItems = [
    {
      title: 'Educational Platform',
      image: 'https://www.example.com/educational.png',
      category: 'Web Design / Usability Testing',

    },
    {
      title: 'Travel App Design',
      image: 'https://www.example.com/travel.png',
      category: 'UX Research / App Design',

    },
    {
      title: 'Personal Page',
      image: 'https://www.example.com/personal.png',
      category: 'Web Design',

    },
    {
      title: 'Furniture Mobile App',
      image: 'https://www.example.com/furniture.png',
      category: 'App Design',

    },
    {
      title: 'Coffee House Landing Page',
      image: 'https://www.example.com/coffee.png',
      category: 'UX Research / Web Design',

    },
    {
      title: 'Home Services Page',
      image: 'https://www.example.com/homeservices.png',
      category: 'Web Design / Marketing',

    },
  ];

  return (
    <section className="portfolio">
      <div className="container">
        <h2 className="section-title">My Portfolio</h2>
        <p className="section-description">Explore my design services, from user interface and experience to prototyping and testing. Let's craft exceptional digital experiences together.</p>
        <table className="portfolio-table">
          <tbody>
            <tr>
              {portfolioItems.slice(0, 3).map((item, index) => (
                <td key={index} className="portfolio-item-cell">
                  <div className="portfolio-item">
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img src={item.image} alt={item.title} className="portfolio-image" />
                      <div className="portfolio-content">
                        <h3 className="portfolio-title">{item.title}</h3>
                        <p className="portfolio-category">{item.category}</p>
                      </div>
                    </a>
                    <div className="portfolio-arrow">
                      <svg viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 010-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              {portfolioItems.slice(3, 6).map((item, index) => (
                <td key={index} className="portfolio-item-cell">
                  <div className="portfolio-item">
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img src={item.image} alt={item.title} className="portfolio-image" />
                      <div className="portfolio-content">
                        <h3 className="portfolio-title">{item.title}</h3>
                        <p className="portfolio-category">{item.category}</p>
                      </div>
                    </a>
                    <div className="portfolio-arrow">
                      <svg viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 010-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Portfolio;
