import React from 'react';
import './Main.css';

function Main() {
  return (
    <div className="container">
      <div className="header">
        <div className="logo">
          <h1>SJ Design</h1>
        </div>
        <div className="nav">
          <ul>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Portfolio</a></li>
            <li><a href="#">Blog</a></li>
          </ul>
        </div>
        <div className="button">
          <button>Let's Talk</button>
        </div>
      </div>
      <div className="main">
        <div className="content">
          <h2>UX Designer</h2>
          <h1>Hi There, I'm</h1>
          <h2>Sarah Jones</h2>
          <p>Welcome to my portfolio of captivating digital experiences. Explore my work and let's create something extraordinary together.</p>
          <div className="buttons">
            <button>Hire Me</button>
            <button>Portfolio</button>
          </div>
        </div>
        <div className="image">

          <img src="https://www.shutterstock.com/image-vector/beautiful-girl-with-long-hair-side-2032673225" alt="profile" />
        </div>
      </div>
    </div>
  );
}


export default Main;