import React from 'react';
import './Services.css';

function Services() {
  return (
    <section className="services">
      <div className="container">
        <h2>Services</h2>
        <p>Explore my design services, from user interface and experience to prototyping and testing. Let's craft exceptional digital experiences together.</p>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="service">
                  <div className="service-icon">
                    {/* Design Icon */}
                  </div>
                  <h3>Design</h3>
                  <p>I specialize in web development and design, creating visually appealing, user-friendly digital experiences.</p>
                </div>
              </td>
              <td>
                <div className="service">
                  <div className="service-icon">
                    {/* Branding Icon */}
                  </div>
                  <h3>Branding</h3>
                  <p>I'm a branding expert, crafting unique visual identities that tell your story and resonate with your audience.</p>
                </div>
              </td>
              <td>
                <div className="service">
                  <div className="service-icon">
                    {/* UX Research Icon */}
                  </div>
                  <h3>UX Research</h3>
                  <p>I specialize in user experience research, collaborating on web development, and ensuring user-friendly digital products.</p>
                </div>
              </td>
              <td>
                <div className="service">
                  <div className="service-icon">
                    {/* Usability Testing Icon */}
                  </div>
                  <h3>Usability Testing</h3>
                  <p>I perform usability testing and optimize designs websites based on real-user feedback for seamless interactions.</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Services;