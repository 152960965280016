import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section className="contact">
      <div className="container">
        <h2>Get in Touch</h2>
        <p>Have a question or a project in mind? I'd love to hear from you. Let's chat and make something amazing together.</p>
        <div className="contact-wrapper">
          <div className="contact-info">
            <div className="contact-item">
              <i className="fas fa-phone"></i>
              <span>+1205 5872 321</span>
            </div>
            <div className="contact-item">
              <i className="fas fa-envelope"></i>
              <span>contact@sarajonesdesign.com</span>
            </div>
            <div className="contact-item">
              <i className="fas fa-map-marker-alt"></i>
              <span>1234 Design Street, Creativeville, Webland, Imaginary State, 98765</span>
            </div>
          </div>
          <div className="contact-form-wrapper">
            <form className="contact-form">
              <input type="text" placeholder="First name" />
              <input type="email" placeholder="Email" />
              <input type="tel" placeholder="Phone number" />
              <textarea placeholder="Your message"></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;