import React from 'react';

function Testimonials() {
  return (
    <section className="testimonials">
      <div className="container">
        <h2>Testimonials</h2>
        <p>Sara Jones's designs are both visually captivating and highly effective. She transformed our website, enhancing user engagement and conversions. Her attention to detail and creative approach are top-notch. Highly recommended!</p>
        <div className="testimonial">
          <div className="testimonial-avatar">
            {/* Image of John Smith */}
          </div>
          <p className="testimonial-text">Sara Jones's designs are both visually captivating and highly effective. She transformed our website, enhancing user engagement and conversions. Her attention to detail and creative approach are top-notch. Highly recommended!</p>
          <p className="testimonial-author">John Smith / CEO of XYZ Company</p>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;