import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-links">
            <ul>
              <li><a href="/">About</a></li>
              <li><a href="/">Services</a></li>
            </ul>
          </div>
          <div className="footer-logo">
            <a href="/">
              <span className="footer-logo-text">SJ</span>
              <span className="footer-logo-text-red">Design</span>
            </a>
          </div>
          <div className='footer-links'>
            <ul>
            <li><a href="/">Portfolio</a></li>
            <li><a href="/">Blog</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <p>Follow me on social media:</p>
            <ul>
              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#"><i className="fab fa-dribbble"></i></a></li>
              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
