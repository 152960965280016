import React from 'react';
import './About.css';

function About() {
  return (
    <section className="about">
      <div className="container">
        <h2>About Me</h2>
        <p>I'm Sara Jones, a UI/UX designer dedicated to crafting intuitive and visually stunning digital experiences. With a passion for user-centric design, I transform ideas into functional and beautiful interfaces. Let's collaborate and shape the future of design together.</p>
        <div className="about-stats">
          <div className="about-stat">
            <span className="about-stat-number">502</span>
            <span className="about-stat-label">Projects Done</span>
          </div>
          <div className="about-stat">
            <span className="about-stat-number">10+</span>
            <span className="about-stat-label">Years of Experience</span>
          </div>
          <div className="about-stat">
            <span className="about-stat-number">273+</span>
            <span className="about-stat-label">Clients Served</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;